// Here you can add other styles
.titleMas {
    font-style: italic;
}

.titleMas-sm {
    font-style: italic;
    font-size: small;
}

.titleMas-sm-m {
    font-style: italic;
    font-size: small;
    margin-top: -6px;
}

.titleMas-m {
    font-style: italic;
    margin-top: -6px;
}
